import ApolloClient from 'apollo-boost'
import config from 'config'

type AppHeaders = {
  authorization: string,
  mobile: string
}

export const getHeaders = (): AppHeaders => {
  const userObj = JSON.parse(localStorage.getItem(config.storageKeys.user) as string)
  return {
    authorization: userObj?.details?.accessToken || '',
    mobile: userObj?.details?.mobileNumber || '',
  }
}

const client = new ApolloClient({
  request: (operation) => {
    operation.setContext({
      headers: getHeaders(),
    })
  },
  uri: `${config.gateway.url}/graphql`,
})

export default client
