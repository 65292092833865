import React from 'react'
import styles from './Location.module.css'
import Button, { ButtonVariant } from 'components/Button'
import { ReactComponent as Location } from 'icons/location_large.svg'
import { composeClasses } from 'libs/utils'
import { useModal } from 'contexts/modal'
import { ILocation } from './Location'
import { useTranslation } from 'react-i18next'

const LocationPermission: React.FC<ILocation> = ({ callbackFn }) => {
  const { setModal } = useModal()
  const { t } = useTranslation()

  const closeModal = () => {
    setModal({
      visible: false,
    })
  }

  return (
    <div className={styles.locationModal}>
      <Location />
      <h3 className={styles.title}>
        {t('locationPermission.pleaseAllowForUs')}
        <br />
        {t('locationPermission.toAccessYour')}
        <br />
        {t('locationPermission.location')}{' '}
      </h3>
      <p>
        {t('locationPermission.thisWillHelpUsAccuratelyAnd')} <br /> {t('locationPermission.automaticallyFillInThe')} <br />{' '}
        {t('locationPermission.fieldsForYourPickupDestination')}.
      </p>
      <div className={styles.actionFlex}>
        <Button
          className={styles.actionBtn}
          onClick={() => callbackFn()}
        >
          {t('auth.otp.continue')}
        </Button>
        <Button
          className={composeClasses(styles.actionBtn, styles.cancelBtn)}
          onClick={closeModal}
          variant={ButtonVariant.Secondary}
        >
          {t('auth.otp.cancel')}
        </Button>
      </div>
    </div>
  )
}

export default LocationPermission
