import React from 'react'

import styles from './Input.module.css'

import { ReactComponent as TrackShipment } from 'icons/track.svg'

import { composeClasses } from 'libs/utils'
import { FieldInputProps, useField } from 'formik'

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
}

const TrackingNumberInput: React.SFC<InputProps> = ({ className, placeholder = 'Type Something...', label, ...props }) => {
  const [field, meta] = useField(props as FieldInputProps<any>)

  const getValidationStyle = () => {
    if (meta.touched && meta.error) return styles.invalid

    return ''
  }

  return (
    <div className={styles.inputContainer}>
      {label &&
      <label
        className={styles.label}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      }
      <div className={composeClasses(styles.trackingInputContainer, className, getValidationStyle())}>
        <div className={composeClasses(styles.firstIcon)}># &#124;</div>
        <input
          className={composeClasses(styles.input, styles.trackingInput)}
          placeholder={placeholder}
          {...field}
          {...props}
        />
        <button
          className={composeClasses(
            styles.searchBtn,
            !field.value && styles.searchBtnEmpty,
          )}
          type='submit'
        >
          <TrackShipment />
        </button>
      </div>
      {meta.touched && meta.error && (
        <div className={styles.errorShower}>
          <p className={styles.validationError}>{meta.error}</p>
        </div>
      )}
    </div>
  )
}

export default TrackingNumberInput
