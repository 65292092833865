import React, { useContext, useEffect, useState } from 'react'
import i18n from 'i18n'
import styles from '../styles/translations.module.css'
import config from 'config'

export const LANGUAGE_EN = 'en'
export const LANGUAGE_AR = 'ar'
export interface ILanguageContext {
  currentLanguage: string,
  setCurrentLanguage: (code: string) => void
  reversedTextStyle: string
  reversedContainerStyle: string
  reversedImageStyle: string
}

const LanguageContext = React.createContext<ILanguageContext>({
  currentLanguage: '',
  reversedContainerStyle: '',
  reversedImageStyle: '',
  reversedTextStyle: '',
  setCurrentLanguage: () => {
  },
})

const defaultLanguage = LANGUAGE_AR
const getSavedLanguageSelection = () => {
  if (!localStorage.getItem(config.storageKeys.language)) {
    localStorage.setItem(config.storageKeys.language, defaultLanguage)
  }

  return localStorage.getItem(config.storageKeys.language)!
}

export const LanguageProvider:React.FC = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(getSavedLanguageSelection())

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
  }, [currentLanguage])

  const isArabic = currentLanguage === LANGUAGE_AR
  const reversedTextStyle = isArabic ? styles.textRTL : ''
  const reversedContainerStyle = isArabic ? styles.containerRTL : ''
  const reversedImageStyle = isArabic ? styles.imageRTL : styles.imageLTR

  const setLanguage = (language: string) => {
    setCurrentLanguage(language)
    localStorage.setItem(config.storageKeys.language, language)
  }

  return (
    <LanguageContext.Provider value={{
      currentLanguage,
      reversedContainerStyle,
      reversedImageStyle,
      reversedTextStyle,
      setCurrentLanguage: setLanguage,
    }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => useContext(LanguageContext)

export default LanguageContext
