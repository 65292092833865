import React, { useState, useContext, ElementType } from 'react'
import Modal from 'components/Modal'
import { noOp } from 'libs/utils'

export interface IModalContext {
  visible: boolean,
  component?: ElementType,
  modalClassname?: string,
  setModal: (params: any) => void,
  showBackArrow?: boolean
}

const ModalContext = React.createContext<IModalContext>({
  modalClassname: '',
  setModal: noOp,
  showBackArrow: false,
  visible: false,
})

const defaultModalState = {
  setModal: noOp,
  title: '',
  visible: false,
}

export const ModalProvider: React.FC = ({ children }) => {
  const [modalState, setModalState] = useState<IModalContext>(defaultModalState)
  return (
    <ModalContext.Provider
      value={{
        ...modalState,
        setModal: params => setModalState(params),
      }}
    >
      {children}
      <Modal {...modalState}/>
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const { setModal, ...state } = useContext(ModalContext)
  return { setModal, state }
}
