/* eslint-disable camelcase */
import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { noOp } from 'libs/utils'
import { AddressDataType, initialAddressData } from 'components/AddressForm/AddressForm'
import config from 'config'
import { PackageDetailsType } from 'components/PackageDetailsForm'
import { UserContext } from './user'
import { SHIPMENT_SETTINGS } from 'services/shipment'

export interface BasicInfoType {
  fullName?: string,
  firstName?: string,
  lastName?: string,
  mobileNumber: string,
  email?: string,
  hasAlternateContact?: boolean
}

export interface ShipmentSettings {
  cities?: string[],
  cities_ar?: string[],
  feedback: {
    category_id: string,
    category_name: string,
    category_type: string,
    category_values: string,
    lang: string,
  }[],
  products: {
    product_group_name: string,
    product_group: string,
    product_type_name: string,
    product_type: string,
    product_id: number,
    weight_slab_max: number,
    weight_slab_min: number,
  }[]
}

export interface ShipmentDataContextProps {
  shipper?: {
    info?: BasicInfoType,
    alternate?: BasicInfoType,
    address?: AddressDataType
  },
  consignee?: {
    address?: AddressDataType
    info?: BasicInfoType
  },
  payment?: {
    estimatedPrice?: number
    actualPrice?: number
  },
  package?: {
    details?: PackageDetailsType,
    timing?: {
      slot?: string
      date?: string
    }
  },
  shipmentID?: string,
  merchantReference?: string
}

export const initialBasicInfo: BasicInfoType = {
  email: '',
  fullName: '',
  hasAlternateContact: false,
  mobileNumber: '',
}

export const initialShipmentDataState: ShipmentDataContextProps = {
  consignee: {
    address: initialAddressData,
    info: initialBasicInfo,
  },
  package: {
    timing: {
      date: '',
      slot: '',
    },
  },
  payment: {
    actualPrice: 0,
    estimatedPrice: 0,
  },
  shipmentID: '',
  shipper: {
    address: initialAddressData,
    alternate: initialBasicInfo,
    info: initialBasicInfo,
  },
}

export const ShipmentDataContext = createContext<{
  shipmentData?: ShipmentDataContextProps
  settings?: ShipmentSettings,
  setShipmentData: React.Dispatch<React.SetStateAction<ShipmentDataContextProps>>
}>({
  setShipmentData: noOp,
  shipmentData: initialShipmentDataState,
})

const cachedShipmentData = localStorage.getItem(config.storageKeys.shipmentFlow)

export const ShipmentDataProvider: React.FC = ({ children }) => {
  const { user } = useContext(UserContext)
  const [shipmentData, setShipmentData] = useState<ShipmentDataContextProps>(
    cachedShipmentData
      ? JSON.parse(cachedShipmentData)
      : initialShipmentDataState,
  )
  const [loadShipmentSettings, { data: shipmentSettings }] = useLazyQuery(SHIPMENT_SETTINGS, {
    fetchPolicy: 'cache-first',
  })

  // Load the shipment settings data if the user is logged in
  useEffect(() => {
    if (!user || shipmentSettings) {
      return
    }

    loadShipmentSettings()
  }, [user, shipmentSettings, loadShipmentSettings])

  // Sync state to localStorage
  useEffect(() => {
    localStorage.setItem(
      config.storageKeys.shipmentFlow,
      JSON.stringify(shipmentData),
    )
  }, [shipmentData])

  const shipmentSettingsData = shipmentSettings?.shipmentData

  return (
    <ShipmentDataContext.Provider
      value={{
        // TODO: Replace with alternate implementation to allow for less spreading
        setShipmentData: params => {
          setShipmentData({
            ...shipmentData,
            ...params,
          })
        },
        settings: {
          ...shipmentSettingsData,
        },
        shipmentData,
      }}
    >
      {children}
    </ShipmentDataContext.Provider>
  )
}

export const useShipmentData = () => {
  return useContext(ShipmentDataContext)
}
