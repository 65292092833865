import React, { useContext } from 'react'
import styles from './modal.module.css'
import { IModalContext, useModal } from 'contexts/modal'
import { composeClasses } from 'libs/utils'
import { ReactComponent as BackArrow } from 'icons/left-arrow.svg'
import LanguageContext from 'contexts/language'

const Modal: React.FC<IModalContext> = ({ visible, component: Component, modalClassname = '', showBackArrow }) => {
  const { setModal } = useModal()
  const { reversedTextStyle } = useContext(LanguageContext)
  const closeModal = () => setModal({ visible: false })

  return (
    <>
      {
        visible &&
        <div
          className={composeClasses(styles.modalContainer, modalClassname, reversedTextStyle)}
        >
          <div
            className={styles.modalBackdrop}
            onClick={closeModal}
          />
          <div className={styles.modalContent}>
            {showBackArrow &&
              <button
                className={styles.backArrow}
                onClick={closeModal}
              >
                <BackArrow />
              </button>
            }
            {Component && (
              <Component />
            )}
          </div>
        </div>
      }
    </>
  )
}

export default Modal
