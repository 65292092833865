import React from 'react'
import { useModal } from 'contexts/modal'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import { ReactComponent as OTP } from 'icons/otp.svg'

import styles from './AuthenticateUserModal.module.css'
import { useTranslation } from 'react-i18next'

const AuthenticateUserModal: React.FC<{}> = () => {
  const { setModal } = useModal()
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <div className={styles.mobileVerificationModal}>
      <OTP className={styles.mobileVerificationImage} />

      <h2 className={styles.mobileVerificationModalTitle}>{t('home.hiThere')}</h2>
      <p>
        {t('home.authenticatePrompt')}
      </p>

      <Button
        className={styles.mobileVerificationModalButton}
        onClick={() => {
          history.push('/auth/login?action=signup')
          setModal({
            visible: false,
          })
        }}
      >
        {t('account.editAccount.verify.continueToMobileVerification')}
      </Button>
    </div>
  )
}

export default AuthenticateUserModal
