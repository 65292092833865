import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Button.module.css'
import { composeClasses } from 'libs/utils'
import { ButtonSize, ButtonVariant } from './Button'
import * as H from 'history'

export interface LinkProps {
  to: string | H.LocationDescriptor<H.LocationState>,
  size?: ButtonSize,
  variant?: ButtonVariant,
  className?: string,
}

const LinkButton: React.SFC<LinkProps> = ({
  children,
  className,
  size = ButtonSize.Large,
  variant = ButtonVariant.Primary,
  ...props
}) => {
  return (
    <Link
      className={composeClasses(
        styles.button,
        styles[size],
        styles[variant],
        className,
      )}
      {...props}
    >
      {children}
    </Link>
  )
}

export default LinkButton
