import React, { lazy, Suspense, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import './i18n'
import { ApolloProvider } from '@apollo/react-hooks'
import client from 'libs/apollo'
import { ModalProvider } from 'contexts/modal'
import { ToastProvider } from 'contexts/toast'
import { ShipmentDataProvider } from 'contexts/shipment'
import { PriceCalculationDataProvider } from 'contexts/priceCalculation'
import SplashPage from 'components/SplashPage/SplashPage'
import { UserContextProps, UserContext, initialUserState } from 'contexts/user'
import config from 'config'
import { AuthDataContext, AuthDataContextProps, initialAuthDataState } from 'contexts/authData'
import firebase from 'services/firebase'
import ProtectedRoute from 'routes/ProtectedRoute/ProtectedRoute'
import { LanguageProvider } from 'contexts/language'
import { logEvent } from 'services/analytics'

const Dashboard = lazy(() => import('./routes/Dashboard/Dashboard'))
const PriceCalculator = lazy(() => import('./routes/PriceCalculator/PriceCalculator'))
const PackageDetails = lazy(() => import('./routes/PriceCalculator/PackageDetails'))
const PriceCalculation = lazy(() => import('./routes/PriceCalculator/PriceCalculation'))
const TrackShipment = lazy(() => import('./routes/TrackShipment/TrackShipment'))
const TrackShipmentResult = lazy(() => import('./routes/TrackShipment/TrackShipmentResult'))

// Shipment
const CreateShipment = lazy(() => import('./routes/shipments/CreateShipment/CreateShipment'))
const AlternateContact = lazy(() => import('./routes/shipments/CreateShipment/AlternateContact'))
const CreateShipmentAddress = lazy(() => import('./routes/shipments/CreateShipment/CreateShipmentAddress'))
const ConsigneeBasicInfo = lazy(() => import('./routes/shipments/ConsigneeInfo/BasicInfo'))
const ConsigneeAddress = lazy(() => import('./routes/shipments/ConsigneeInfo/ConsigneeAddress'))
const WhatToShip = lazy(() => import('./routes/shipments/CreateShipment/WhatToShip/WhatToShip'))
const PriceEstimation = lazy(() => import('./routes/shipments/PriceEstimation'))
const PackageTiming = lazy(() => import('./routes/shipments/CreateShipment/PackageTiming/PackageTiming'))
const ShipmentSummary = lazy(() => import('./routes/shipments/CreateShipment/ShipmentSummary/ShipmentSummary'))
const Shipments = lazy(() => import('./routes/shipments/Shipments/Shipments'))
const ShipmentDetail = lazy(() => import('./routes/shipments/ShipmentDetail/ShipmentDetail'))
const RateShipment = lazy(() => import('routes/shipments/RateShipment/RateShipment'))

// Auth
const Login = lazy(() => import('./routes/auth/Login/Login'))
const Signup = lazy(() => import('./routes/auth/Signup/Signup'))
const SignupAddress = lazy(() => import('./routes/auth/Signup/SignupAddress'))
const VerifyOTP = lazy(() => import('./routes/auth/VerifyOTP/VerifyOTP'))
const SignupEntry = lazy(() => import('./routes/auth/SignupEntry/SignupEntry'))

// Payment
const PaymentMethod = lazy(() => import('./routes/Payment/PaymentMethod'))
const PrepaidPaymentMethod = lazy(() => import('./routes/Payment/PrepaidMethod/PrepaidPaymentMethod'))
const CreditCardPage = lazy(() => import('./routes/Payment/PrepaidMethod/CreditCardPage'))
const PaymentFail = lazy(() => import('./routes/Payment/PaymentStatus/PaymentFail'))
const PaymentSuccess = lazy(() => import('./routes/Payment/PaymentStatus/PaymentSuccess'))
const CashPayment = lazy(() => import('./routes/Payment/CashPayment'))
const PaymentProcessing = lazy(() => import('./routes/Payment/PaymentProcessing'))

// Account
const CustomerInformation = lazy(() => import('./routes/account/CustomerInformation/CustomerInformation'))
const EditCustomerInfo = lazy(() => import('./routes/account/EditCustomerInfo/EditCustomerInfo'))
const EditCustomerAddress = lazy(() => import('routes/account/EditCustomerAddress/EditCustomerAddress'))

// Static pages
const PrivacyPolicy = lazy(() => import('routes/StaticPages/PrivacyPolicy'))
const AboutUs = lazy(() => import('routes/StaticPages/AboutUs'))
const TermsAndConditions = lazy(() => import('routes/StaticPages/TermsAndConditions'))
const CreditPage = lazy(() => import('routes/StaticPages/Credit'))

const PaymentRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
      >
        <PaymentMethod />
      </Route>

      <Route
        exact
        path={`${path}/prepaid`}
      >
        <PrepaidPaymentMethod />
      </Route>

      <Route path={`${path}/card`}>
        <CreditCardPage />
      </Route>

      <Route
        exact
        path={`${path}/cash`}
      >
        <CashPayment />
      </Route>

      <Route
        exact
        path={`${path}/success`}
      >
        <PaymentSuccess />
      </Route>

      <Route
        exact
        path={`${path}/fail`}
      >
        <PaymentFail />
      </Route>

      <Route
        exact
        path={`${path}/processing`}
      >
        <PaymentProcessing />
      </Route>
    </Switch>
  )
}

const CreateShipmentFlowRoutes = () => {
  const { path } = useRouteMatch()
  return (

    <Switch>
      <Route
        exact
        path={`${path}/`}
      >
        {/* Create a new shipment */}
        <CreateShipment />
      </Route>

      <Route
        exact
        path={`${path}/alternate-contact`}
      >
        <AlternateContact />
      </Route>

      <Route path={`${path}/address`}>
        <CreateShipmentAddress />
      </Route>

      <Route path={`${path}/package/details`}>
        <WhatToShip />
      </Route>

      <Route path={`${path}/package/estimate`}>
        <PriceEstimation />
      </Route>

      <Route path={`${path}/package/when`}>
        <PackageTiming />
      </Route>

      <Route path={`${path}/payment`}>
        <PaymentRoutes />
      </Route>

      <Route
        exact
        path={`${path}/consignee`}
      >
        <ConsigneeBasicInfo />
      </Route>

      <Route
        path={`${path}/consignee/address`}
      >
        <ConsigneeAddress />
      </Route>

      <Route
        path={`${path}/summary`}
      >
        <ShipmentSummary />
      </Route>
    </Switch>
  )
}

const ShipmentRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`${path}/`}
      >
        {/* List shipments */}
        <Shipments />
      </ProtectedRoute>

      <Route
        exact
        path={`${path}/:shipmentId`}
      >
        <ShipmentDetail />
      </Route>

      <Route
        exact
        path={`${path}/:shipmentId/rate`}
      >
        <RateShipment />
      </Route>

    </Switch>
  )
}

const AuthRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/login`}>
        <Login />
      </Route>

      <Route path={`${path}/signup`}>
        <SignUpRoutes />
      </Route>

      <Route path={`${path}/otp`}>
        <VerifyOTP />
      </Route>
    </Switch>
  )
}

const SignUpRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
      >
        <SignupEntry />
      </Route>
      <Route
        exact
        path={`${path}/info`}
      >
        <Signup />
      </Route>
      <Route
        path={`${path}/address`}
      >
        <SignupAddress />
      </Route>
    </Switch>
  )
}

const AccountManagementRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
      >
        <CustomerInformation />
      </Route>

      <Route
        exact
        path={`${path}/edit/info`}
      >
        <EditCustomerInfo />
      </Route>

      <Route
        exact
        path={`${path}/edit/address`}
      >
        <EditCustomerAddress />
      </Route>
    </Switch>
  )
}

const PriceCalculatorRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <PriceCalculationDataProvider>
      <Switch>
        <Route
          exact
          path={`${path}/`}
        >
          <PriceCalculator />
        </Route>

        <Route
          exact
          path={`${path}/package`}
        >
          <PackageDetails />
        </Route>

        <Route
          exact
          path={`${path}/price`}
        >
          <PriceCalculation />
        </Route>
      </Switch>
    </PriceCalculationDataProvider>
  )
}

function App() {
  const cachedUserData = localStorage.getItem(config.storageKeys.user)
  const cachedAuthData = localStorage.getItem(config.storageKeys.authFlow)

  const [authData, setAuthData] = useState<AuthDataContextProps>(
    cachedAuthData
      ? JSON.parse(cachedAuthData)
      : initialAuthDataState,
  )

  const [user, setUser] = useState<UserContextProps>(
    cachedUserData
      ? JSON.parse(cachedUserData)
      : initialUserState,
  )

  // Sync user data to localStorage for speedy reloads
  useEffect(() => {
    localStorage.setItem(config.storageKeys.user, JSON.stringify(user))
  }, [user])

  // Persist auth data to store to allow for reloading
  useEffect(() => {
    localStorage.setItem(config.storageKeys.authFlow, JSON.stringify(authData))
  }, [authData])

  // Initialize Firebase analytics & performance
  useEffect(() => {
    firebase.initializeApp(config.firebase)
    firebase.analytics()

    // if the app opening has not been logged, log it
    if (!localStorage.getItem(config.storageKeys.accessLog)) {
      logEvent('app_opened')
      localStorage.setItem(config.storageKeys.accessLog, 'accessed')
    }

    if (config.firebase.fcmKey) {
      if (firebase.messaging.isSupported()) {
        firebase.messaging().usePublicVapidKey(config.firebase.fcmKey)
      }
    }
  }, [])

  return (
    <ApolloProvider client={client}>
      <AuthDataContext.Provider value={{
        authData,
        setAuthData,
      }}
      >
        <UserContext.Provider
          value={{
            setUser: params => {
              setUser({
                ...user,
                ...params,
              })
            },
            user,
          }}
        >
          <LanguageProvider>
            <ShipmentDataProvider>
              <Suspense fallback={<SplashPage />}>
                <Router>
                  <ToastProvider>
                    <ModalProvider>
                      <Switch>
                        <Route
                          exact
                          path='/'
                        >
                          <Dashboard />
                        </Route>

                        <Route path='/calculator'>
                          <PriceCalculatorRoutes />
                        </Route>

                        <ProtectedRoute path='/shipments'>
                          <ShipmentRoutes />
                        </ProtectedRoute>

                        <ProtectedRoute
                          path={'/shipment/create'}
                        >
                          {/* Create  shipment routes */}
                          <CreateShipmentFlowRoutes />
                        </ProtectedRoute>

                        <Route
                          path='/auth'
                        >
                          <AuthRoutes />
                        </Route>

                        <Route path='/account'>
                          <AccountManagementRoutes />
                        </Route>

                        <Route
                          exact
                          path='/track'
                        >
                          <TrackShipment />
                        </Route>

                        <Route
                          exact
                          path='/track/:shipmentId'
                        >
                          <TrackShipmentResult />
                        </Route>

                        <Route
                          exact
                          path={'/privacy'}
                        >
                          <PrivacyPolicy />
                        </Route>

                        <Route
                          exact
                          path={'/about'}
                        >
                          <AboutUs />
                        </Route>
                        <Route
                          exact
                          path={'/terms-and-conditions'}
                        >
                          <TermsAndConditions />
                        </Route>
                        <Route
                          exact
                          path={'/credits'}
                        >
                          <CreditPage />
                        </Route>
                      </Switch>
                    </ModalProvider>
                  </ToastProvider>
                </Router>
              </Suspense>
            </ShipmentDataProvider>
          </LanguageProvider>
        </UserContext.Provider>
      </AuthDataContext.Provider>
    </ApolloProvider >

  )
}

export default App
