import React from 'react'

import styles from './Spinner.module.css'
import { composeClasses } from 'libs/utils'

export interface SpinnerProps {
  className?: string
  centered?: boolean
}

const Spinner: React.SFC<SpinnerProps> = ({ centered, className }) => {
  const classes = composeClasses(
    styles.spinner,
    centered && styles.centered,
    className,
  )

  return (
    <div className={classes}>
      <div className={styles.doubleBounce1}></div>
      <div className={styles.doubleBounce2}></div>
    </div>
  )
}

export default Spinner
