import React from 'react'

import Spinner from '../Spinner/Spinner'

import styles from './Button.module.css'
import { composeClasses } from 'libs/utils'

export enum ButtonSize {
  Large = 'lg',
  Small = 'sm',
}
export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}
export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean,
  size?: ButtonSize,
  variant?: ButtonVariant
}

const Button: React.SFC<ButtonProps> = ({
  children,
  className,
  disabled,
  size = ButtonSize.Large,
  loading,
  variant = ButtonVariant.Primary,
  ...props
}) => {
  const buttonDisabled = loading || disabled

  return (
    <button
      className={composeClasses(
        styles.button,
        styles[size],
        styles[variant],
        loading && styles.loading,
        className,
      )}
      disabled={buttonDisabled}
      {...props}
    >
      {
        loading
          ? <Spinner />
          : children}
    </button>
  )
}

export default Button
