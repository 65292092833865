import React from 'react'
import { ReactComponent as LocationSVG } from 'icons/location.svg'
import styles from './Location.module.css'
import LocationPermission from './LocationPermission'
import { useModal } from 'contexts/modal'
import { KeyValueType } from 'types'
import { isNotEmptyArray } from 'libs/utils'
import config from 'config'
import LocationDenied from './LocationDenied'
import { useTranslation } from 'react-i18next'
import { useLanguageContext } from 'contexts/language'

export interface ILocation {
  callbackFn: Function
  onInit?: () => void
  onComplete?: () => void
}

const generateAddressFields = (address: KeyValueType) => {
  const { address_components: addressComponents, formatted_address: formattedAddress } = address.results[0]
  if (isNotEmptyArray(addressComponents)) {
    const address = addressComponents.reduce((acc: KeyValueType, currentItem: KeyValueType) => {
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes

      // Add all the types and not only the first: The order is alphabetical and that can cause us to lose the more specific data
      // The less specific can get overridden but we would only use the more specific data to pull the details
      const types = currentItem.types
      for (const key in types) {
        if (Object.prototype.hasOwnProperty.call(types, key)) {
          const type = types[key]
          acc[type] = currentItem.long_name
        }
      }
      return acc
    }, {})

    // Add formattedAddress to the address list if it exist
    if (formattedAddress) {
      address.formattedAddress = formattedAddress
    }

    return address
  }
}

const Location: React.SFC<ILocation> = ({ callbackFn, onInit, onComplete }) => {
  const { setModal } = useModal()
  const { t } = useTranslation()
  const { currentLanguage } = useLanguageContext()

  const showErrorModal = () => {
    setModal({
      component: LocationDenied,
      visible: true,
    })
  }

  const getAddress = ({ longitude, latitude }: KeyValueType) => {
    const { geoCode: { url, key } } = config
    const addressUrl = `${url}?latlng=${latitude},${longitude}&key=${key}&language=${currentLanguage.toLowerCase()}`
    return fetch(addressUrl)
      .then(res =>
        res.json().then(data => {
          if (!res.ok) {
            throw Error(data.err || 'HTTP error')
          }
          return data
        }),
      )
      .then(addressData => {
        return addressData
      })
      .catch(() => {
        // show error modal
        showErrorModal()
        return null
      })
  }

  const success = async (position: KeyValueType) => {
    // eslint-disable-next-line no-unused-expressions
    onInit?.()
    const geoData = await getAddress(position.coords)
    if (geoData) {
      const address = generateAddressFields(geoData)
      typeof callbackFn === 'function' && callbackFn(address)
    }
    // eslint-disable-next-line no-unused-expressions
    onComplete?.()
  }

  const error = () => {
    showErrorModal()
    // eslint-disable-next-line no-unused-expressions
    onComplete?.()
  }

  const getLocationData = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      showErrorModal()
    }
  }

  const openModal = () => {
    setModal({
      // eslint-disable-next-line react/display-name
      component: () => (
        <LocationPermission callbackFn={getLocationData} />
      ),
      visible: true,
    })
  }

  const locationHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    // Experimental: https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API/Using_the_Permissions_API
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            return getLocationData()
          } else if (result.state === 'denied') {
            throw Error('permission denied')
          }
          openModal()
        })
        .catch(() => {
          showErrorModal()
        })
    } else {
      openModal()
    }
  }

  return (
    <a
      className={styles.locationBtn}
      href="#location"
      onClick={locationHandler}
    >
      <LocationSVG /> {t('shipment.create.address.useMyCurrentLocation')}
    </a>
  )
}

export default Location
