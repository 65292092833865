import config from 'config'
import firebase from 'services/firebase'

export const logEvent = (
  eventName: string,
  eventParams?: { [key: string]: any },
) => {
  // On local env, disable analytics but show output to console for debugging purposes
  if (!config.app.isProduction) {
    /* eslint-disable no-console */
    console.groupCollapsed(`Event Triggered: ${eventName}`)
    console.log('Name: ', eventName)
    if (eventParams) {
      console.log('Params: ', JSON.stringify(eventParams))
    }
    console.groupEnd()
    /* eslint-enable no-console */
    return
  }

  firebase.analytics().logEvent(eventName, eventParams)
}
