import React from 'react'

import styles from './SplashPage.module.css'
import Spinner from 'components/Spinner/Spinner'

export interface SplashPageProps {

}

const SplashPage: React.FC<SplashPageProps> = () => {
  return (
    <div className={styles.splashPage}>
      <Spinner />
    </div>
  )
}

export default SplashPage
