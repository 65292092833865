import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Button from 'components/Button'
import { CityInput, TextField } from 'components/Form/Input'
import { KeyValueType } from 'types'
import styles from './AddressForm.module.css'
import { useModal } from 'contexts/modal'
import Location from 'components/Location'
import Spinner from 'components/Spinner/Spinner'
import { useShipmentData } from 'contexts/shipment'
import { useTranslation } from 'react-i18next'
import { mapToLowerCase, getLocationAddress } from 'libs/utils'
import { useToast } from 'contexts/toast'
import { LANGUAGE_AR, useLanguageContext } from 'contexts/language'

export interface AddressDataType {
  address?: string
  city?: string
  default_address?: string
  latitude?: string
  longitude?: string
  neighborhood?: string
}

export const initialAddressData: AddressDataType = {
  address: '',
  city: '',
  default_address: 'False', // 'False' to match the API spec
  neighborhood: '',
}
export interface AddressFormProps {
  buttonActionText: string // Action text on the button
  customValues?: AddressDataType // Override the initial form values if needed
  onSubmit: Function
  validate?: (values: AddressDataType) => AddressDataType // Optional validation
  title?: string
  useCurrentLocation?: boolean
}

const defaultValidation = (values: KeyValueType, cityList: string[], cityErrorFn: Function) => {
  const errors: KeyValueType = {}

  // All fields are required
  for (const property in values) {
    if (!values[property]) {
      errors[property] = 'Required'
    }
  }

  if (values.city && !mapToLowerCase(cityList).includes(values.city.toLowerCase())) {
    errors.city = `${values.city} is invalid`
    cityErrorFn()
  }

  return errors
}

const AddressForm: React.SFC<AddressFormProps> = ({
  buttonActionText,
  customValues,
  onSubmit,
  validate,
  title,
  useCurrentLocation,
}) => {
  const [locationRetrievalInProgress, setLocationRetrievalInProgress] = useState(false)
  const [address, setAddress] = useState<AddressDataType | undefined>(customValues)
  const { setModal } = useModal()
  const { t } = useTranslation()
  const { addError } = useToast()
  const { currentLanguage } = useLanguageContext()
  const settings = useShipmentData().settings
  const cityList = (currentLanguage === LANGUAGE_AR ? settings?.cities_ar : settings?.cities) || []

  const locationCB = (address: KeyValueType) => {
    const initialAddress = getLocationAddress(address)
    setAddress(initialAddress)
    setModal({
      visible: false,
    })
    setLocationRetrievalInProgress(false)
  }

  return (
    <>
      {
        useCurrentLocation &&
        <div className={styles.flexContainer}>
          <h3 className={styles.formHeading}>{title}</h3>
          <Location
            callbackFn={locationCB}
            onComplete={() => {
              setLocationRetrievalInProgress(false)
            }}
            onInit={() => {
              setLocationRetrievalInProgress(true)
            }}
          />
        </div>
      }

      {locationRetrievalInProgress
        ? (
          <div className={styles.locationSpinner}>
            <Spinner />
          </div>
        )
        : (
          <Formik
            enableReinitialize
            initialValues={customValues || address || initialAddressData}
            onSubmit={(values: AddressDataType, { setSubmitting }) => onSubmit(values, setSubmitting)}
            validate={(values) => {
              return typeof validate === 'function' ? validate(values) : defaultValidation(values, cityList, () => {
                addError('Please provide a city from the dropdown')
              })
            }}
            validateOnMount
          >
            {
              ({ isValid, values, isSubmitting }) => {
                const { address, city, neighborhood } = values

                return (
                  <Form>
                    <CityInput
                      label={t('shipment.create.address.city')}
                      name="city"
                      type="text"
                      value={city}
                    />

                    <TextField
                      label={t('shipment.create.address.neighborhood')}
                      name="neighborhood"
                      type="text"
                      value={neighborhood}
                    />

                    <TextField
                      label={t('shipment.create.address.address')}
                      name="address"
                      type="text"
                      value={address}
                    />

                    <Button
                      className={styles.submitBtn}
                      disabled={!isValid}
                      loading={isSubmitting}
                      type="submit"
                    >
                      {buttonActionText}
                    </Button>
                  </Form>
                )
              }
            }
          </Formik >
        )
      }
    </>
  )
}

export default AddressForm
