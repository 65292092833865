import React, { createContext, useState, useContext } from 'react'
import { noOp } from 'libs/utils'
import { AddressType } from 'routes/PriceCalculator/PriceCalculator'
import { PackageDetailsType } from 'components/PackageDetailsForm'

export const initialPriceCalculationDataState = {
  address: {
    consigneeCity: '',
    shipperCity: '',
  },
}

export interface PriceCalculationDataContextProps {
  address?: AddressType,
  package?: PackageDetailsType
  price?: number
}

export const PriceCalculationDataContext = createContext<{
  priceCalculationData?: PriceCalculationDataContextProps
  setPriceCalculationData: React.Dispatch<React.SetStateAction<PriceCalculationDataContextProps>>
}>({
  priceCalculationData: initialPriceCalculationDataState,
  setPriceCalculationData: noOp,
})

export const PriceCalculationDataProvider: React.FC = ({ children }) => {
  const [priceCalculationData, setPriceCalculationData] = useState<PriceCalculationDataContextProps>(initialPriceCalculationDataState)
  return (
    <PriceCalculationDataContext.Provider
      value={{
        priceCalculationData,
        setPriceCalculationData: params => {
          setPriceCalculationData({
            ...priceCalculationData,
            ...params,
          })
        },

      }}
    >
      {children}
    </PriceCalculationDataContext.Provider>
  )
}

export const usePriceCalculationData = () => {
  return useContext(PriceCalculationDataContext)
}
