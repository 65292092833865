import { createContext } from 'react'
import { noOp } from 'libs/utils'
import { KeyValueType } from 'types'

export const initialAuthDataState = {
  mobileNumber: '',
}

export interface AuthDataContextProps extends KeyValueType {
  mobileNumber: string
}

export const AuthDataContext = createContext<{
  authData?: AuthDataContextProps
  setAuthData: React.Dispatch<React.SetStateAction<AuthDataContextProps>>
}>({
  authData: initialAuthDataState,
  setAuthData: noOp,
})
