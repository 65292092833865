import React from 'react'
import styles from './Location.module.css'
import Button from 'components/Button'
import { ReactComponent as Alert } from 'icons/alert.svg'
import { useModal } from 'contexts/modal'
import { useTranslation } from 'react-i18next'

const LocationDenied: React.SFC = () => {
  const { setModal } = useModal()
  const { t } = useTranslation()

  const closeModal = () => {
    setModal({
      visible: false,
    })
  }

  return (
    <div className={styles.locationModal}>
      <Alert className={styles.alertSVG} />
      <h3 className={styles.title}>{t('locationDenied.weAreUnableToAccessYourLocation')}</h3>
      <p>
        {t('locationDenied.pleaseCompleteTheFormManually')}
      </p>
      <Button
        className={styles.continueBtn}
        onClick={closeModal}
      >Continue</Button>
    </div>
  )
}

export default LocationDenied
