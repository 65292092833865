import { KeyValueType } from 'types'

import { TFunction } from 'i18next'

export const composeClasses = (...styles: Array<string | boolean | undefined>): string => styles.filter(item => item).join(' ')

const hours = new Date().getHours()
export const isDayTime = hours > 6 && hours < 16

export const noOp = () => { }

export const isNotEmptyArray = (arr: Array<any>): boolean => Array.isArray(arr) && arr.length > 0

export const formatMoney = (value: number, showCurrency: true = true): string => {
  const amount = new Intl.NumberFormat('en-US').format(value)

  if (showCurrency) {
    return `${amount} SAR`
  }

  return amount
}

export const isEmailValid = (email: string) => {
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegExp.test(email)
}

// Allow only Saudi Arabia numbers
// Sample phone number: 0505330609
export const isMobileValid = (mobile: string) => {
  const mobileRegExp = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
  return mobileRegExp.test(mobile)
}

export const addMobilePrefix = (mobile: string, format?: boolean): string => {
  let mobileNumber = ''
  const matchRegex = /^(00966|966|\+966)/
  if (matchRegex.test(mobile)) {
    mobileNumber = mobile
  } else {
    mobileNumber = `966${mobile}`
  }

  return format ? mobileNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '+($1) $2-$3-$4') : mobileNumber
}

const getAddress = (address: any) => {
  if (address?.__typename) {
    delete address.__typename
  }

  return address
}

export const getUserDetails = (userObject: any) => {
  const getValue = (param: string) => {
    if (!param || param === 'false') return ''
    return param
  }

  return ({
    accessToken: getValue(userObject.access_token),
    accountCode: getValue(userObject.account_code),
    address: getAddress(userObject.address[0]) || {},
    email: getValue(userObject.email),
    firstName: getValue(userObject.first_name),
    id: getValue(userObject.id),
    lastName: getValue(userObject.last_name),
    mobileNumber: getValue(userObject.mobile) || getValue(userObject.phone),
    userStatus: getValue(userObject.user_status),
  })
}

export const normalizeUserObject = (userObject: any) => ({
  email: userObject.email || '',
  phone: userObject.mobileNumber || '',
  user_name: userObject.fullName || '',
})

export const convertTimestampToDate = (isoString: string) => {
  const dateObj = new Date(isoString)
  return {
    date: `${dateObj.toLocaleString('default', { day: 'numeric', month: 'long' })}`,
    time: `${dateObj.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`,
  }
}

export const convertIsoDateToTime = (isoString: string) => {
  const dateObj = new Date(isoString)
  return `${dateObj.toLocaleString('en-US', { hour: 'numeric', hour12: false, minute: 'numeric' })}`
}

export const translateServerMessage = (t: TFunction, message: string, default_value: string | undefined = undefined) => t(`server_messages.${message}`, default_value || message, undefined)

export const displayPackageName = (name: string) => name && name.toLowerCase() === 'non-document' ? 'Parcel' : name

export const getLocalISOString = (date: Date): string => {
  const localISOString = (new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 3, date.getMinutes(), date.getSeconds(), date.getMilliseconds())).toISOString().replace(/z/i, '')
  return localISOString
}

export const mapToLowerCase = (stringArray: string[] = []) => stringArray.map(item => item.toLowerCase())

export const generateUniqueRef = (shipmentID = '') => {
  return `${shipmentID}_${Date.now()}`
}

export const getLocationAddress = (address: KeyValueType) => {
  const getValue = (key: string, defaultValue = '') =>
    address[key] || defaultValue

  return {
    address:
      getValue('formattedAddress') ||
      `${getValue('street_number')} ${getValue('route')}`,
    city: getValue('locality') || getValue('political'),
    neighborhood:
      getValue('sublocality') ||
      getValue('sublocality_level_1') ||
      getValue('administrative_area_level_1'),
  }
}
