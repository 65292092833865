import React, { useEffect } from 'react'
import styles from './toast.module.css'
import { useToast } from 'contexts/toast'
import { ReactComponent as Cross } from 'icons/cross.svg'
import config from 'config'
import { useTranslation } from 'react-i18next'
import { composeClasses, translateServerMessage } from 'libs/utils'

const Toast: React.FC = () => {
  const { error, removeError, notification, removeNotification } = useToast()
  const { t } = useTranslation()
  const toastDuration = Number(config.app.toastDuration) || 3000
  const toastData = (error && {
    clickAction: removeError,
    message: error.message,
    toastStyle: styles.errorToast,
  }) ||
    (notification && {
      clickAction: removeNotification,
      message: notification.message,
      toastStyle: styles.notificationToast,
    })

  useEffect(() => {
    const timer = setTimeout(() => {
      removeError()
      removeNotification()
    }, toastDuration)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <>
      {
        toastData &&
        <div
          className={composeClasses(styles.toastContainer, toastData.toastStyle)}
        >
          <span className={styles.toastMessage}>{translateServerMessage(t, toastData.message)}</span>
          <button
            className={styles.closeBtn}
            onClick={() => toastData.clickAction()}
          ><Cross/></button>
        </div>
      }
    </>
  )
}

export default Toast
