import React, { useState, useCallback, useContext } from 'react'
import { KeyValueType } from 'types'
import { noOp } from 'libs/utils'
import Toast from 'components/Toast'

export interface INotificationData {
  message: string,
  link: string
}

export interface IToastContext {
  addError: Function
  addNotification: Function
  error: KeyValueType | null
  removeError: Function
  removeNotification: Function
  substitutions?: KeyValueType
  notification: INotificationData | null
}

export const ToastContext = React.createContext<IToastContext>({
  addError: noOp,
  addNotification: noOp,
  error: null,
  notification: null,
  removeError: noOp,
  removeNotification: noOp,
})

export const ToastProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<KeyValueType | null>(null)
  const [notification, setNotification] = useState<INotificationData | null>(null)
  const removeError = () => setError(null)
  const addError = (message: string, status?: string, substitutions?: KeyValueType) => setError({ message, status, substitutions })

  const removeNotification = () => setNotification(null)
  const addNotification = (notification: INotificationData) => setNotification(notification)

  const contextValue = {
    addError: useCallback((message, status, substitutions) => addError(message, status, substitutions), []),
    addNotification: useCallback((notification) => addNotification(notification), []),
    error,
    notification,
    removeError: useCallback(() => removeError(), []),
    removeNotification: useCallback(() => removeNotification(), []),
  }

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toast />
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  return useContext(ToastContext)
}
