import { createContext } from 'react'
import { noOp } from 'libs/utils'
import { AddressDataType, initialAddressData } from 'components/AddressForm/AddressForm'

export interface UserDetails {
  address?: AddressDataType
  fullName?: string
  firstName?: string
  lastName?: string
  id?: string
  mobileNumber?: string,
  email?: string
  accountCode?: string
}
export interface UserContextProps {
  details: UserDetails
  isLoggedIn?: boolean
}
export const initialUserState: UserContextProps = {
  details: {
    address: initialAddressData,
    email: '',
    fullName: '',
    id: '',
    mobileNumber: '',
  },
  isLoggedIn: false,
}

export const UserContext = createContext<{
  setUser: React.Dispatch<React.SetStateAction<UserContextProps>>
  user: UserContextProps
}>({
  setUser: noOp,
  user: initialUserState,
})
