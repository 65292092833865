import { gql } from 'apollo-boost'

export const AVAILABLE_WEIGHTS = gql`
query {
  availableWeights {
    id
    name
  }
}
`
export const RATE_SHIPMENT = gql`
mutation rateShipment($data: ShipmentFeedbackData!) {
  rateShipment(input: $data) {
    status
  }
}
`

export const GET_SHIPMENT = gql`
query($id: String!) {
  trackShipment(shipment: $id) {
    id
    driver_phone_number
    tracking_number
    senders_name
    feedback
    shipment
    product_type
    current_update {
      update
      update_id
      update_code
      date
      location_name
      location_code
      time
    }
    status
    description
    pieces
    weight
    creation_date
    origin_city
    origin_country
    destenation_city
    destenation_country
    history {
      update
      update_id
      update_code
      date
      location_name
      location_code
      time
    }
  }
}

`
export const GET_SHIPMENTS = gql`
query {
  shipments {
    product_type,
    description,
    shipment,
    status,
    current_update {
      update,
      time,
      date
    },
    history {
      time,
      date
    }
  }
}
`

export const SHIPMENT_SETTINGS = gql`
  query {
    shipmentData {
      cities
      cities_ar
      feedback{
        category_id
        category_name
        category_type
        category_values
        lang
      }
      products{
        product_type_name
        product_type
        product_group
        product_group_name
        product_id
        weight_slab_max
        weight_slab_min
      }
    }
  }
`

export const GET_SHIPMENT_RATE = gql`
  mutation getShipmentRate($data: ShipmentRateInput!){
    shipmentRate(data: $data){
      currency
      price
    }
  }
`

export const GET_SHIPMENT_ESTIMATE = gql`
  mutation getShipmentEstimate($data: ShipmentRateInput!){
    shipmentEstimate(data: $data){
      currency
      price
    }
  }
`

export const GET_PICKUP_AVAILABILITY = gql`
  query getPickupDates($data: PickupAvailabilityInput){
    pickupAvailability(data: $data){
      data {
        pickup_slots {
          lower_slot
          upper_slot
        }
      }
    }
  }
`

export const CREATE_PICKUP = gql`
  mutation createPickup($data: CreatePickupInput!){
    createPickup(input: $data){
      data {
        pickup_id
        customer_reference1
      }
    }
  }
`

export const CREATE_SHIPMENT = gql`
  mutation createShipment($data: CreateShipmentInput!){
    createShipment(input: $data){
      data {
        shipment
        customer_reference1
        labelUrl
      }
    }
  }
`
