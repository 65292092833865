import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import config from './config'

import translationEN from './locales/en/translation.json'
import translationAR from './locales/ar/translation.json'
import { LANGUAGE_EN } from './contexts/language'

const resources = {
  ar: {
    translation: translationAR,
  },
  en: {
    translation: translationEN,
  },
}

i18n
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: config.app.translation.default || LANGUAGE_EN,
    resources,
  })

export default i18n
