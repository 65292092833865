import React, { useContext } from 'react'
import { useField, FieldInputProps } from 'formik'
import { composeClasses } from 'libs/utils'
import styles from './Input.module.css'
import LanguageContext, { LANGUAGE_EN } from 'contexts/language'

export enum TextFieldVariant {
  Full = 'full',
  Partial = 'partial'
}

export interface ITextFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type?: string
  label?: string
  placeholder?: string
  RenderInput?: React.FC<{
    field: FieldInputProps<any>,
    inputProps: any
  }>
  variant?: TextFieldVariant,
  overrideRTL?: boolean
}

const TextField: React.SFC<ITextFieldProps> = ({
  className,
  label,
  overrideRTL = false,
  RenderInput,
  variant = TextFieldVariant.Full,
  ...props
}) => {
  const [field, meta] = useField(props as any)
  const { currentLanguage } = useContext(LanguageContext)
  const invalidStyle = composeClasses(styles.invalid, overrideRTL || currentLanguage === LANGUAGE_EN ? styles.invalidLTR : styles.invalidRTL)
  const validStyle = composeClasses(styles.valid, overrideRTL || currentLanguage === LANGUAGE_EN ? styles.validLTR : styles.validRTL)
  const disabledStyle = composeClasses(styles.disabled, overrideRTL || currentLanguage === LANGUAGE_EN ? styles.disabledLTR : styles.disabledRTL)

  const validationStyle = () => {
    // email fields are optional
    if (!meta.touched || (props?.type === 'email' && !meta.value)) return ''

    if (meta.error) return invalidStyle

    return validStyle
  }

  return (
    <div className={composeClasses(
      styles.inputContainer,
      styles[variant],
    )}
    >
      {label &&
      <label
        className={styles.label}
        htmlFor={props.id || props.name}
      >{label}</label>
      }

      <div
        className={composeClasses(
          styles.input,
          validationStyle(),
          props.disabled && disabledStyle,
          className,
        )}
      >
        {
          RenderInput
            ? (
              <RenderInput
                field={field}
                inputProps={props}
              />
            )
            : (
              <input
                {...field}
                {...props}
              />
            )
        }
      </div>
      {meta.touched && meta.error && (
        <div className={styles.errorShower}>
          <p className={styles.validationError}>{meta.error}</p>
        </div>
      )}
    </div>
  )
}

export default TextField
