import { LANGUAGE_EN } from 'contexts/language'

const config = {
  app: {
    isProduction: process.env.NODE_ENV === 'production',
    toastDuration: process.env.REACT_APP_TOAST_DURATION,
    translation: {
      default: LANGUAGE_EN,
    },
  },
  applePay: {
    isEnabled: process.env.REACT_APP_ENABLE_APPLE_PAY === 'true', // this is to get a boolean value
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
    fcmKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_PUSH_KEY,
    measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  },
  gateway: {
    url: process.env.REACT_APP_GATEWAY_URL,
  },
  geoCode: {
    key: process.env.REACT_APP_GEOCODE_KEY,
    url: process.env.REACT_APP_GEOCODE_URL,
  },
  payment: {
    accessCode: process.env.REACT_APP_PAYMENT_GATEWAY_ACCESS_CODE,
    madaBins: process.env.REACT_APP_PAYMENT_MADA_BINS,
    merchantID: process.env.REACT_APP_PAYMENT_GATEWAY_MERCHANT_ID,
    passPhrase: process.env.REACT_APP_PAYMENT_GATEWAY_REQUEST_PASS,
    url: process.env.REACT_APP_PAYMENT_GATEWAY_URL,
  },
  storageKeys: {
    accessLog: 'zajil.al',
    authFlow: 'zajil.af',
    language: 'zajil.lang',
    previousPickupDataCache: 'zajil.cache.p',
    previousShipmentDataCache: 'zajil.cache.s',
    pushNotification: 'zajil.push',
    shipmentFlow: 'zajil.sf',
    user: 'zajil.u',
  },
}

export default config
