import React, { useState } from 'react'
import { FieldInputProps, useField } from 'formik'
import TextField, { ITextFieldProps } from './TextField'
import styles from './Input.module.css'
import { isNotEmptyArray, composeClasses } from 'libs/utils'
import { ReactComponent as MapSVG } from 'icons/map.svg'
import { useShipmentData } from 'contexts/shipment'
import useOnclickOutside from 'react-cool-onclickoutside'
import { LANGUAGE_AR, useLanguageContext } from 'contexts/language'

const OptionItem: React.SFC<{
  item: string,
  onClick: () => void,
}> = ({ item, onClick, ...props }) => {
  return (
    <li
      onClick={onClick}
      {...props}
    >
      <MapSVG />
      {item}
    </li>
  )
}

const CityInput: React.FC<ITextFieldProps> = ({ label, ...props }) => {
  const [value, setValue] = useState('')
  const [visible, setVisible] = useState(false)
  const { currentLanguage } = useLanguageContext()
  const settings = useShipmentData().settings
  const cityList = (currentLanguage === LANGUAGE_AR ? settings?.cities_ar : settings?.cities) || []
  const [filteredOptions, setFilteredOptions] = useState<string[]>(cityList)
  const [, , { setValue: setFormikValue }] = useField(props as FieldInputProps<any>)
  const ref = useOnclickOutside(() => {
    setVisible(false)
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setValue(inputValue)
    setFormikValue(inputValue)

    if (!inputValue) {
      setFilteredOptions(cityList)
      return
    }

    // filter the city list
    const suggestList = cityList.filter(
      (city) => city.toLowerCase().includes(value.toLowerCase()),
    )

    setFilteredOptions(suggestList)
  }

  const setOptionItem = (value: string) => {
    setValue(value)
    setFormikValue(value)
    setVisible(false)
  }

  const hasFilteredOptions = isNotEmptyArray(filteredOptions)

  return (
    <div className={composeClasses(styles.autocompleteContainer)}>
      <TextField
        autoComplete="off"
        className={visible && hasFilteredOptions ? styles.focus : ''}
        label={label}
        onChange={handleChange}
        onClick={() => setVisible(true)}
        onKeyDown={e => {
          if ([13, 27].includes(e.keyCode)) setVisible(false)
        }}
        type='text'
        value={value}
        {...props}
      />
      {visible && isNotEmptyArray(filteredOptions) &&
        <ul
          className={composeClasses(styles.autocompleteList, visible && styles.focus)}
          ref={ref}
        >
          {filteredOptions.map(item => (
            <OptionItem
              item={item}
              key={item}
              onClick={() => setOptionItem(item)}
            />
          ))}

        </ul>
      }
    </div>
  )
}

export default CityInput
