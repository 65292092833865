import React, { useContext, useEffect } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { UserContext } from 'contexts/user'
import { useModal } from 'contexts/modal'
import AuthenticateUserModal from './AuthenticateUserModal'

const ProtectedRoute: React.FC<{
  path: string;
  exact?: boolean;
}> = ({ children, ...rest }) => {
  const { user: { isLoggedIn } } = useContext(UserContext)
  const { setModal } = useModal()
  const history = useHistory()
  const location = useLocation()

  const isPaymentPage = location.pathname === '/shipment/create/payment'

  useEffect(() => {
    // so that we can prevent the app from loading the Protected route page
    // and load the previous page
    const shouldLoadModalOnSamePage = history.action === 'PUSH'
    // FIXME: remove the check for payment route after payment is sorted
    if (!isPaymentPage && !isLoggedIn) {
      setModal({
        component: AuthenticateUserModal,
        visible: true,
      })
      if (shouldLoadModalOnSamePage) {
        history.goBack()
      } else {
        history.push('/')
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn, history])

  return (
    <Route
      {...rest}
      render={() =>
        (isPaymentPage || isLoggedIn) && (
          children
        )
      }
    />
  )
}

export default ProtectedRoute
